// Latest posts Slider
$(document).ready(function(){
    // Main scroller
    $('.latest__banner').slick({
        autoplay: true,
        autoplaySpeed: 6000,
        dots: false,
        dotsClass: 'latest__dots',
        arrows: true,
        prevArrow: '<span class="slick-prev"></span>',
        nextArrow: '<span class="slick-next"></span>',
        centerMode: true,
        centerPadding: '0',
        slidesToShow: 3,
        // centerPadding: true,
        // slidesToScroll: 3,
        vertical: false,

        responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1
            }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
        ]
    });

    // Youtube video API emulation
    
    $( ".video__overlay" ).click(function() {
        $( this ).toggleClass( "video__overlay--active" );
    });

    $( ".toggler" ).click(function() {
        $( this ).toggleClass( "toggler--active" );
    });

    $( ".toggler" ).click(function() {
        $( ".navbar--main" ).toggleClass( "navbar--active" );
    });

    $( ".article__image" ).click(function() {
        $( this ).toggleClass( "article__image--zoom" );
    });
});